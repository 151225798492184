@import url('https://fonts.googleapis.com/css2?family=Honk&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-title {
  font-family: "Honk", system-ui;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "MORF" 15, "SHLN" 50;
}

a {
  text-decoration: underline;
  color: inherit;
}

/* Add this to your index.css or a global CSS file */

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

header, main, footer {
  width: 100%;
  margin: 0 auto;
}

.hidden-overflow {
  overflow: hidden !important;
}

.full-width {
  width: 100vw;  /* Ensure full viewport width */
  overflow-x: hidden;
}

.hero-section {
  width: 100%;  /* Ensure full width */
  margin: 0;
  padding: 0;
}

.container {
  max-width: 100%;  /* Ensure full width */
  padding: 0 15px;
}

.footer {
  width: 100%;  /* Ensure full width */
  margin-top: auto;
}
